<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div
		v-else-if="!isNotFound"
		class="main-wrapper-large"
	>
		<!-- General information -->
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 mt-4 d-flex align-items-center">
					Shipment #{{ shipment.code }}
					<CBadge
						:color="shipmentStatus.color"
						class="badge-status mr-auto"
						data-test-id="badge-shipment-status"
					>
						{{ shipmentStatus.title }}
					</CBadge>
					<div class="btn-actions">
						<CButton
							color="tertiary"
							class="mr-2"
							@click="printShipment"
						>
							<CIcon
								name="cil-print"
								class="mr-2"
							/>
							Print
						</CButton>
						<template v-if="hasManageShipmentPermission">
							<CButton
								v-if="canCancelShipment"
								color="tertiary"
								class="mr-3"
								@click="$refs['modal-shipment-cancel'].open()"
							>
								Cancel
							</CButton>

							<CButton
								v-if="canBookCourier"
								color="primary"
								@click="$refs['modal-courier-book'].open()"
							>
								Book courier
							</CButton>
							<CButton
								v-else-if="canMarkAsShipped"
								:disabled="edit.isUpdating"
								color="primary"
								@click="openModalConfirm(SHIPMENT_STATUS.SHIPPED)"
							>
								Mark as shipped
							</CButton>
							<CButton
								v-else-if="canMarkAsDelivered"
								:disabled="edit.isUpdating"
								color="primary"
								@click="openModalConfirm(SHIPMENT_STATUS.DELIVERED)"
							>
								Mark as delivered
							</CButton>
							<CButton
								v-else-if="canMarkAsPickedUp"
								:disabled="edit.isUpdating"
								color="primary"
								@click="openModalConfirm(SHIPMENT_STATUS.PICKED_UP)"
							>
								Mark as picked up
							</CButton>
						</template>
					</div>
				</h2>
			</CCol>
		</CRow>
		<CRow class="summary">
			<CCol md="12">
				<p class="typo-body-2 m-0 p-0">
					Last updated by: <span class="color-hypertext">back office system</span><span class="date color-black-45">{{ shipment.updatedAt }}</span>
				</p>
				<p class="typo-body-2 m-0 p-0">
					Order Id:
					<router-link
						:to="{
							name: 'OrderDetail',
							params: { id: shipment.orderId }
						}"
						class="order-details-link"
					>
						{{ shipment.orderCode }}
					</router-link>
				</p>
				<p class="typo-body-2 mt-1">
					Order Status:
					<CBadge
						:color="orderStatus.color"
						class="badge-status"
					>
						{{ orderStatus.title }}
					</CBadge>
				</p>
			</CCol>
		</CRow>
		<CRow class="customer">
			<CCol md="12">
				<h3 class="typo-h5 mt-3">
					Customer
				</h3>
				<p class="typo-h6 mb-1">
					{{ `${shipment.customer.firstName} ${shipment.customer.lastName}` }}
				</p>
				<div class="d-inline-block">
					<label class="typo-label color-black-45">ID: <span class="typo-body-2 font-weight-normal color-black">{{ shipment.customer.id }}</span></label>
					<label class="typo-label color-black-45 ml-4">Customer group: <span class="typo-body-2 font-weight-normal color-black">{{ shipment.customer.customerGroup.name }}</span></label>
					<label class="typo-label color-black-45 ml-4">Phone: <span class="typo-body-2 font-weight-normal color-black">{{ shipment.customer.phoneNumber }}</span></label>
					<label class="typo-label color-black-45 ml-4">Email: <span class="typo-body-2 font-weight-normal color-black">{{ shipment.customer.email }}</span></label>
				</div>
				<p class="typo-h6 mt-2 mb-1">
					Additional infomation
				</p>
				<p
					class="customer-note w-50 typo-body-2 color-black-45"
					data-test-id="customer-note"
				>
					{{ shipment.orderNote | nullableToDashFormat }}
				</p>
			</CCol>
		</CRow>
		<!-- End General information -->

		<!-- Shipment -->
		<CRow class="mb-3">
			<CCol>
				<ShipmentDetailShipping
					:shipment="shipment"
					show-air-waybill
				/>
			</CCol>
		</CRow>
		<!-- End Shipment -->

		<!-- Table -->
		<CRow class="mb-5">
			<CCol>
				<div
					class="product-table mt-4"
					data-test-id="product-table"
				>
					<BaseTable
						:fields="SHIPMENT_DETAIL_TABLE_FIELDS"
						:items="dataTable"
						class="table-custom"
					>
						<template #no-items-view>
							<div class="empty-table-element">
								<p class="subtitle">
									{{ $t("global.searchNotFound", { field: "orders" }) }}
								</p>
							</div>
						</template>
						<template #product="{item}">
							<div class="product-info">
								<img
									:src="item.thumbnail"
									:alt="item.name"
									class="thumbnail thumbnail-40"
								>
								<div class="description">
									<p class="mb-0">
										{{ item.name }}
									</p>
									<p class="color-black-45 mb-0">
										SKU: {{ item.sku }}
									</p>
									<CBadge v-if="item.type === ORDER_ITEM_TYPE.FREEBIE" class="badge badge-freebie mt-2">
										Freebie
									</CBadge>
								</div>
							</div>
						</template>
					</BaseTable>
					<div v-if="dataTable.length" class="product-table-summary pt-2" data-test-id="product-table-summary">
						<div class="row total-price">
							<label class="col typo-label mb-0 pl-0">Total packed item(s)</label>
							<span class="col typo-body-2 text-right mb-0 pr-0">{{ shipment.quantity }}</span>
						</div>
					</div>
				</div>
			</CCol>
		</CRow>
		<!-- End Table -->
		<ModalCourierBook ref="modal-courier-book" />
		<ModalShipmentCancel ref="modal-shipment-cancel" />
		<BaseModalConfirm
			ref="modal-confirm"
			:is-submitting="edit.isUpdating"
			:title="modalInfo.title"
			:description="modalInfo.content"
			@onConfirm="handleMarkAsStatus"
		/>
	</div>
	<Error404 v-else />
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapState, mapGetters, mapActions } from 'vuex';
import ShipmentDetailShipping from '@/components/ShipmentDetailShipping.vue';
import ModalCourierBook from '@/components/ModalCourierBook.vue';
import ModalShipmentCancel from '@/components/ModalShipmentCancel.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import Error404 from '@/components/Error404.vue';

import {
	SHIPMENT_COLOR,
	SHIPMENT_TITLE,
	SHIPMENT_DETAIL_TABLE_FIELDS,
	SHIPMENT_STATUS,
} from '../enums/shipments';
import {
	ORDER_COLOR,
	ORDER_TITLE,
	ORDER_ITEM_TYPE,
} from '../enums/orders';
import { capitalize } from '../assets/js/helpers';

export default {
	name: 'ShipmentDetail',

	components: {
		ShipmentDetailShipping,
		ModalCourierBook,
		ModalShipmentCancel,
		BaseModalConfirm,
		Error404,
	},

	data() {
		return {
			isShow: false,
			markAsStatus: '',
			shipmentId: Number(this.$route.params.id),
			SHIPMENT_DETAIL_TABLE_FIELDS,
			ORDER_ITEM_TYPE,
		};
	},

	computed: {
		...mapState('shipments', {
			detail: 'detail',
			edit: 'edit',
		}),
		...mapGetters({
			shipment: 'shipments/shipmentDetail',
			hasManageShipmentPermission: 'profile/hasManageShipmentPermission',
		}),
		SHIPMENT_STATUS: () => SHIPMENT_STATUS,
		modalInfo() {
			const statusMapper = {
				[SHIPMENT_STATUS.SHIPPED]: 'shipped',
				[SHIPMENT_STATUS.DELIVERED]: 'delivered',
				[SHIPMENT_STATUS.PICKED_UP]: 'picked up',
			};
			const title = `Confirm mark as '${capitalize(statusMapper[this.markAsStatus])}'?`;
			const content = `Shipment status will be changed to '${statusMapper[this.markAsStatus]}' and it can't be undone.`;
			return {
				title,
				content,
			};
		},
		isLoading() {
			return this.detail.isLoading;
		},
		shipmentStatus() {
			return {
				title: SHIPMENT_TITLE.SHIPMENT[this.shipment.status],
				color: SHIPMENT_COLOR.SHIPMENT[this.shipment.status],
			};
		},

		dataTable() {
			return this.shipment && this.shipment.items ? this.shipment.items.map((item) => ({
				id: item.id,
				product: {
					name: item.name,
					sku: item.sku,
					thumbnail: item.thumbnail,
					type: item.type,
				},
				quantity: item.quantity,
			})) : [];
		},
		canCancelShipment() {
			return [
				SHIPMENT_STATUS.READY_TO_BOOK_LOGISTIC,
				SHIPMENT_STATUS.READY_TO_SHIP,
				SHIPMENT_STATUS.BOOKING_FAILED,
				SHIPMENT_STATUS.SHIPPING_FAILED,
			].includes(this.shipment.status);
		},
		canBookCourier() {
			return [
				SHIPMENT_STATUS.READY_TO_BOOK_LOGISTIC,
				SHIPMENT_STATUS.BOOKING_FAILED,
			].includes(this.shipment.status);
		},
		canMarkAsShipped() {
			return [
				SHIPMENT_STATUS.READY_TO_SHIP,
			].includes(this.shipment.status);
		},
		canMarkAsDelivered() {
			return [
				SHIPMENT_STATUS.SHIPPED,
			].includes(this.shipment.status);
		},
		canMarkAsPickedUp() {
			return [
				SHIPMENT_STATUS.PICKED_UP,
			].includes(this.shipment.status);
		},
		isNotFound() {
			return isEmpty(this.detail) || isEmpty(this.detail.data);
		},
		orderStatus() {
			return {
				title: ORDER_TITLE.ORDER[this.shipment.orderStatus],
				color: ORDER_COLOR.ORDER[this.shipment.orderStatus],
			};
		},
	},
	async created() {
		await this.getShipment(this.shipmentId);
		this.setPageTitle({
			title: `Shipment #${this.shipment.code}`,
			isShow: true,
		});
	},

	methods: {
		...mapActions({
			getShipment: 'shipments/getShipment',
			setPageTitle: 'pageTitle/setPageTitle',
			updateShipment: 'shipments/updateShipment',
		}),
		async openModalConfirm(status) {
			this.markAsStatus = status;
			this.$refs['modal-confirm'].open();
		},
		async handleMarkAsStatus() {
			const shipmentId = this.$route.params.id;
			const params = { status: this.markAsStatus };
			await this.updateShipment({ id: shipmentId, params });
		},
		printShipment() {
			window.print();
		},
	},
};
</script>

<style lang="scss" scoped>
	.summary {
		.date {
			&::before {
				content: "|";
				display: inline-block;
				margin-left: rem(5);
				margin-right: rem(5);
			}
		}
	}

	.customer {
		&-note {
			border-radius: rem(4);
			min-height: rem(72);
			padding: rem(16);

			background-color: $color-gray-100;
		}
	}

	.badge-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
	}

	.product-table {
		border: rem(1) solid $color-gray-300;
		border-radius: rem(4);

		.product-cel {
			.product-info {
				display: flex;

				.thumbnail {
					flex: 0 0 rem(40);

					border-radius: rem(4);
					border: rem(1) solid $color-gray-300;
				}

				.description {
					flex: 1;
					margin-left: rem(16);
				}
			}
		}

		&-summary {
			max-width: rem(350);
			margin: 0 rem(16) rem(16) auto;

			.total-price {
				border-bottom: rem(3) solid $color-black;

				margin: 0;
				padding: 0 0 rem(16);
			}
		}

		.empty-table-element {
			margin: 0;
		}
	}

	::v-deep .product-table {
		.table-custom {
			margin: 0;
			border-radius: rem(4);
			text-align: right;

			thead > tr > th:first-child,
			tbody > tr > td:first-child {
				padding-left: rem(16);
			}

			thead > tr > th:last-child,
			tbody > tr > td:last-child {
				padding-right: rem(16);
			}

			thead {
				th {
					padding: rem(12) rem(4);
					background-color: $color-gray-100;
					width: 14%;
				}
			}

			tbody {
				td {
					padding: rem(12) rem(4);
					word-break: unset;
				}
			}

			.product {
				&-col, &-cel {
					text-align: left;
					word-break: break-word;
				}
			}

			.quantity {
				&-col, &-cel {
					text-align: center;
					word-break: break-word;
				}
			}

			// Override column width
			.product-col {
				width: 34%;
			}

			.quantity-col {
				width: 9%;
			}

			.total-col {
				width: auto;
			}
		}
	}

	/**
	* Print
	*/
	@media print {
		.btn-actions {
			display: none;
		}

		.customer {
			// .customer-note
			&-note {
				min-height: auto;
				border-radius: 0;
				padding: rem(4) 0 rem(30);
				background-color: transparent;
				margin-bottom: 0 !important;
			}
		}

		.order-details-link {
			color: $color-black-45;
			text-decoration: none;
		}

		::v-deep .product-table {
			border: none;
			margin-top: 0 !important;
		}
	}
</style>
