<template>
	<CModal
		:show.sync="isShow"
		centered
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				title="Cancel this shipment?"
				@onClose="close"
			/>
		</template>
		<template #default>
			Cancel this shipment will affect all selected product packed and warehouse, you will need to create new shipment in order to ship products.
		</template>
		<template #footer>
			<CButton
				class="mr-3"
				color="tertiary"
				@click="close"
			>
				Not now
			</CButton>
			<CButton
				color="danger"
				:disabled="detail.isCanceling"
				@click="handleCancel"
			>
				{{ detail.isCanceling ? "Canceling" : "Cancel shipment" }}
			</CButton>
		</template>
	</CModal>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'ModalCourierCancel',
	components: {
		ModalHeaderWrapper,
	},
	data() {
		return {
			isShow: false,
		};
	},
	computed: {
		...mapState('shipments', {
			detail: 'detail',
		}),
	},
	methods: {
		...mapActions({
			cancelShipment: 'shipments/cancelShipment',
		}),
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
		async handleCancel() {
			const shipmentId = this.$route.params.id;
			await this.cancelShipment(shipmentId);
		},
	},
};
</script>
