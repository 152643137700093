<template>
	<div>
		<CModal
			:show.sync="isShow"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					title="Book courier"
					@onClose="close"
				/>
			</template>
			<template #default>
				<BaseDropDown
					v-model="selectedCourier"
					:options="COURIER_OPTIONS"
					label="name"
					label-drop-down="Couriers"
				/>
			</template>
			<template #footer>
				<CButton
					class="mr-3"
					color="tertiary"
					@click="close"
				>
					Cancel
				</CButton>
				<CButton
					color="primary"
					:disabled="detail.isBooking"
					@click="handleBook"
				>
					{{ detail.isBooking ? "Booking" : "Book" }}
				</CButton>
			</template>
		</CModal>
		<BaseModalLoading ref="modal-loading" modal-title="Courier booking" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import { COURIER_OPTIONS, DEFAULT_COURIER } from '../enums/shipments';

export default {
	name: 'ModalCourierBook',
	components: {
		ModalHeaderWrapper,
	},
	data() {
		return {
			isShow: false,
			selectedCourier: DEFAULT_COURIER,
		};
	},
	computed: {
		...mapState('shipments', {
			detail: 'detail',
		}),
		COURIER_OPTIONS: () => COURIER_OPTIONS,
	},
	methods: {
		...mapActions({
			bookCourier: 'shipments/bookCourier',
		}),
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
		},
		async handleBook() {
			const shipmentId = this.$route.params.id;
			const courierName = this.selectedCourier.name;
			const params = {
				courier: this.selectedCourier.value,
			};
			try {
				this.$refs['modal-loading'].open();
				await this.bookCourier({ id: shipmentId, params, courierName });
			} finally {
				this.$refs['modal-loading'].close();
			}
		},
	},
};
</script>
