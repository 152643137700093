<template>
	<div class="card custom-card mt-4 mb-0">
		<div class="card-header typo-h5 d-flex align-items-center">
			Shipment
			<!-- <CBadge
				:color="shippingStatus.color"
				class="badge-shipping-status"
				data-test-id="badge-shipping-status"
			>
				{{ shippingStatus.title }}
			</CBadge> -->
			<CButton
				v-if="showAirWaybillButton"
				color="secondary"
				class="btn-air-waybill ml-auto"
				:disabled="isPrinting"
				@click="handlePrintAirWaybill"
			>
				<img src="/assets/images/icon-print.svg" class="mr-2" alt="icon print AWB">	{{ isPrinting ? 'Printing' : 'Print AWB' }}
			</CButton>
		</div>
		<div class="card-body pb-2">
			<CRow>
				<CCol>
					<h3 class="typo-h6">
						Shipment info
					</h3>
					<div>
						<label class="shipping-info-label mb-0">
							Shipment method: <span class="shipping-info-value">{{ shipment.shipmentMethod.name }}</span>
						</label>
						<label class="shipping-info-label mb-0">
							Courier: <span class="shipping-info-value">{{ courier }}</span>
						</label>
						<!-- <label class="shipping-info-label">
							Tracking number: <span class="shipping-info-value" :class="{'color-hypertext' : shipment.trackingNumber && shipment.trackingNumber !== '-'}">{{ shipment.trackingNumber }}</span>
						</label> -->
						<!-- Remove this when Sokochan is able to create tracking number and automatic pass to website -->
						<CRow class="align-items-center">
							<CCol>
								<CInput
									v-model.trim="$v.trackingNumber.$model"
									:is-valid="!$v.trackingNumber.$error && null"
									:invalid-feedback="$t('global.error.required')"
									label="Tracking number"
									class="mb-1"
								/>
								<CButton
									color="secondary"
									class="mt-2"
									:disabled="isUpdating"
									@click="updateTrackingNumber"
								>
									Update
								</CButton>
							</CCol>
						</CRow>
					</div>
				</CCol>
				<CCol>
					<OrderAddressDetails
						title="Shipping address"
						:address="shipment.shippingAddress"
					/>
				</CCol>
			</CRow>
		</div>
	</div>
</template>

<script>
import printJS from 'print-js';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import OrderAddressDetails from '@/components/OrderAddressDetails.vue';
import { printAirWaybillAPI, updateTrackingNumberAPI } from '../services/api/shipments.api';
import { SHIPMENT_STATUS, COURIER } from '../enums/shipments';

export default {
	name: 'ShipmentDetailShipping',

	components: {
		OrderAddressDetails,
	},
	validations: {
		trackingNumber: {
			required,
		},
	},
	props: {
		shipment: {
			type: Object,
			default: null,
		},
		showAirWaybill: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isPrinting: false,
			COURIER,
			trackingNumber: null,
			isUpdating: false,
		};
	},
	computed: {
		showAirWaybillButton() {
			if (!this.showAirWaybill) {
				return false;
			}

			return this.shipment.status === SHIPMENT_STATUS.READY_TO_SHIP;
		},
		courier() {
			return COURIER[this.shipment.courier] || '-';
		},
	},
	created() {
		this.trackingNumber = this.shipment.trackingNumber;
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),

		async handlePrintAirWaybill() {
			this.isPrinting = true;
			try {
				const response = await printAirWaybillAPI(this.shipment.id);

				// Create a Blob from the PDF Stream
				const file = new Blob(
					[response.data],
					{ type: 'application/pdf' },
				);

				// Build a URL from the file
				const objectURL = URL.createObjectURL(file);

				printJS(objectURL);
			} catch (e) {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: 'Failure',
					type: 'danger',
				});
			} finally {
				this.isPrinting = false;
			}
		},

		// Remove this when Sokochan is able to create tracking number and automatic pass to website
		async updateTrackingNumber() {
			if (this.isUpdating) {
				return;
			}

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			this.isUpdating = true;

			try {
				await updateTrackingNumberAPI(this.shipment.id, { tracking_number: this.trackingNumber });
				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Tracking number has been updated`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					content: this.$t('global.errorMessage'),
					header: 'Failure',
					type: 'danger',
				});
			} finally {
				this.isUpdating = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.shipping-info {
		// .shipping-info-label
		&-label {
			@include typo-label;

			display: block;
			color: $color-black-45;
		}

		// .shipping-info-value
		&-value {
			@include typo-body-2;

			color: $color-black;
			font-weight: 400;
		}
	}

	.badge-shipping-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
	}

	.shipping-table {
		.badge-status {
			margin-left: 0;
		}
	}

	::v-deep .shipping-table {
		margin: 0 0 rem(-12);
		overflow: hidden;

		.table {
			margin-bottom: 0;
		}

		thead > tr > th,
		tbody > tr > td {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		thead {
			th {
				padding: rem(12) rem(4);
			}
		}

		tbody {
			tr {
				&:last-child {
					td {
						border-bottom: none;
					}
				}

				td {
					padding: rem(12) rem(4);
					word-break: unset;
				}
			}
		}

		.warehouse {
			&-col, &-cel {
				text-align: center;
				word-break: break-word;
			}
		}

		.packed-item {
			&-col, &-cel {
				text-align: center;
				word-break: break-word;
			}
		}

		.courier {
			&-col, &-cel {
				text-align: center;
				word-break: break-word;
			}
		}

		// Override column width
		.shipping-col {
			width: 25%;
		}

		.empty-table-element {
			height: auto;
			padding: rem(24) 0;
		}
	}

	/**
	* Print
	*/
	@media print {
		.btn-air-waybill {
			display: none;
		}
	}
</style>
